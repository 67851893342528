import React from "react";
import { Link } from "gatsby";
import { HeroContentWrap, Hero, HeroWrapper, BreadCrumbFlex, TextSide, Eyebrow, Heading, Text, HeadingWrap } from "./styles/PodcastListingHero.styled";
import FeaturedPodcast from "./PodcastFeaturedPost";
import { StaticImage } from "gatsby-plugin-image";
import MktoForm from "../Form/MktoForm";

const PodcastListingHero = ({ featuredPodcast }) => (
  <HeroWrapper>
    <StaticImage className="background-image" src="../../../static/images/podcast-hero.webp" alt=""/>
    <Hero>
      <BreadCrumbFlex>
        <Link href="/">
          <span>Home</span>
        </Link>
        <img
          src="/icons/chevron-right-white.svg"
          alt="Breadcrumb Chevron"
        />
        <span>Podcasts</span>
      </BreadCrumbFlex>
      <HeroContentWrap>
        <TextSide>
          <HeadingWrap>
            <Eyebrow>Workgrid Podcasts</Eyebrow>
            <Heading>Tune in to our stories</Heading>
            <Text>Subscribe to the Workgrid Podcast and get notified when a new episode is released.</Text>
          </HeadingWrap>
          <MktoForm formId={1828} component={{type: "podcast"}} />
        </TextSide>
        <FeaturedPodcast item={featuredPodcast} />
      </HeroContentWrap>
    </Hero>
  </HeroWrapper>
)

export default PodcastListingHero
